<template>
  <div class="header" id="header_preview" style="justify-content:center;" v-if="showHeader">
    <div class="header_preview" v-if="landing.branding.header_preview" style="overflow:hidden;margin-bottom:20px;" :style="`border-bottom-right-radius:${landing.branding.header_size.radius}px;border-bottom-left-radius:${landing.branding.header_size.radius}px;width:100%;height:${landing.branding.header_size.width}px;background-size:100% 100%;`"></div>
    <div
      class="canvas__header"
      v-if="
        (page === 'home' && landing.branding.header_image && !landing.branding.header_preview) ||
        (page === 'advanced' &&
          landing.branding.header_image &&
          landing.advanced.tab === 1 &&
        !landing.branding.header_preview) || page === 'menuStyles'&& landing.branding.header_image && !landing.branding.header_preview
      "
      :style="`display:block;margin:0 auto;height:${landing.branding.header_size.width}px;width:100%;border-bottom-left-radius:${landing.branding.header_size.radius}px;border-bottom-right-radius:${landing.branding.header_size.radius}px;background-image:url(${landing.branding.header_image});background-size:100% 100%;background-repeat:no-repeat;`"
    >
      <div
        class="canvas__lang__lite"
        style="padding-top: 20px"
        v-if="
          landing.advanced.show_lang &&
          landing.advanced.global.lang_style === 'lite' &&
          page !== 'topMenu'
        "
      >
        <v-select
          style="flex: 0.7"
          flat
          dense
          outlined
          hide-details
          placeholder="Select language"
          :items="langs"
          item-text="name"
          item-value="value"
          :color="landing.branding.font_color"
          :item-color="landing.branding.font_color"
          :background-color="landing.branding.bg_color"
          class="canvas__select"
          @change="changeLang"
          v-model="landing.advanced.default_lang"
        >
          <template v-slot:selection="{ item }">
            <img
              style="height: 20px; width: 30px; margin-right: 10px"
              :src="item.flag"
              :alt="item.name"
            />
                            <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
          </template>
          <template v-slot:item="{ item }">
            <img
              style="height: 20px; width: 30px; margin-right: 10px"
              :src="item.flag"
              :alt="item.name"
            />
                            <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
          </template>
        </v-select>
      </div>
      <div
        class="canvas__lang__pro"
        style="padding-top: 20px"
        v-if="
          landing.advanced.show_lang &&
          landing.advanced.global.lang_style === 'pro' &&
          page !== 'topMenu'
        "
      >
        <img
          style="width: 30px; height: 20px; cursor: pointer"
          :src="flag"
          alt="flag"
        />
      </div>
    </div>
    <div
      class="canvas__header"
      v-if="
        !landing.branding.header_preview&&
        page !== 'home' &&
        page !== 'lining' &&
        page !== 'underConstructor' &&
        page !== 'advanced' &&
        $store.state.listing.listingGroup === null &&
        landing.advanced.global.active_header_inside &&
        page !== 'lining' &&
        page !== 'intro' &&
        page !== 'reviews' &&
        page !== 'order' &&
        page !== 'profile' && page !== 'menuStyles'
      "
      :style="`height:${landing.branding.header_size.width}px;width:100%;border-radius:${landing.branding.header_size.radius}px;display:block;background-image:url(${landing.branding.header_image});margin:0 auto;background-size:100% 100%;background-repeat:no-repeat;`"
    >
      <div
          v-if="page !== 'topMenu'"
        class="canvas__lang__pro"
        :style="`border-radius:${landing.branding.topMenu.styles.radius}px;width:${
          +landing.branding.topMenu.styles.size * 1.5
        }px;height:${
          +landing.branding.topMenu.styles.size * 1.5
        }px;background:${landing.branding.topMenu.styles.background};`"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 9;
          left: 10px;
          top: 20px;
          padding: 0;
          display: flex;
        "
      >
        <v-icon
          :size="landing.branding.topMenu.styles.size"
          :style="`width: ${landing.branding.topMenu.styles.size}px;height:${landing.branding.topMenu.styles.size}px;`"
          :color="landing.branding.topMenu.styles.color"
          @click.stop="drawer = !drawer"
          >mdi-chevron-left</v-icon
        >
      </div>
      <div
          v-if="page !== 'topMenu'"
        class="canvas__lang__pro"
        :style="`border-radius:${landing.branding.topMenu.styles.radius}px;border-radius:${landing.branding.topMenu.styles.radius}px;width:${
          +landing.branding.topMenu.styles.size * 1.5
        }px;height:${
          +landing.branding.topMenu.styles.size * 1.5
        }px;background:${landing.branding.topMenu.styles.background};`"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 9;
          right: 10px;
          top: 20px;
          padding: 0;
          display: flex;
        "
      >
        <v-icon
          :size="landing.branding.topMenu.styles.size"
          :style="`width: ${landing.branding.topMenu.styles.size}px;height:${landing.branding.topMenu.styles.size}px;`"
          :color="landing.branding.topMenu.styles.color"
          @click.stop="drawer = !drawer"
          >mdi-menu</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HeaderPreview",
  props: ["page"],
  data(){
    return{
      drawer:false,
    }
  },
  computed: {
    ...mapState(["landing"]),
      showHeader(){
        if(this.page === 'loader'){
            return false
        }
          if(this.page === 'sidebar'){
              return false;
          }
          if(this.page === 'topMenu'){
              return false;
          }
          if(this.page === 'intro'){
              return false;
          }
          if(this.$store.state.events.page === 'detail'){
              return false;
          }
        return true
      },
    flag: function () {
      const lang = this.landing.advanced.langs.find(
          (x) => x.value === this.landing.advanced.default_lang
      );
      return lang.flag;
    },
    langs() {
      let langs = [];
      this.$store.state.langs.forEach((lang) => {
        this.landing.selected_langs.forEach((select_lang) => {
          if (lang.value === select_lang) {
            langs.push(lang);
          }
        });
      });

      return langs;
    },
  },
  methods:{
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
  }
};
</script>

<style>
</style>
